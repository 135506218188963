<template>
  <div class="settings">
    <button
      class="fixed text-4xl px-3 top-10 right-1 font-light lg:text-4xl lg:top-10 lg:right-0 lg:px-3.5 text-white"
      style="z-index: 1"
      @click="goBack"
    >
      &times;
    </button>
    <div class="user-settings">
      <div class="lg:overflow-y-auto">
        <div id="user-avatar">
          <h2
            class="text-center uppercase text-primary font-semibold tracking-wide"
          >
            {{ userInfo.nickname }}
          </h2>
          <button
            class="mx-auto relative rounded-full bg-gray-800"
            style="filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))"
            @click.prevent="uploadAvatarImg"
          >
            <img
              :src="showAvatar"
              id="avatar-cover"
              class="h-full w-full object-cover"
              @error="replaceByDefault"
              alt="User avatar"
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 md:h-9 md:w-9 absolute right-1 bottom-1 bg-gray-800 rounded-full p-1"
              style="filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
          </button>

          <input
            type="file"
            id="imgupload"
            accept="image/png, image/jpeg, image/jpg"
            style="display: none"
            ref="imgUpload"
            @change="uploadCustomAvatar"
          />

          <div class="flex justify-center md:gap-x-5 gap-2 flex-wrap">
            <img
              v-for="avatar in avatars"
              :key="avatar.name"
              @click="changeAvatar(avatar.name)"
              :src="avatar.src"
              class="avatar-img"
              :class="{
                'border-secondary': userInfo.avatar == avatar.name,
                'border-transparent': userInfo.avatar != avatar.name,
              }"
              :style="{ 'background-color': avatar.bg }"
              style="filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))"
              @error="avatar.src = '/img/default_img.jpg'"
            />
            <img
              v-if="showCustomAvatar"
              class="w-12 h-12 md:w-16 md:h-16 object-cover border-2 rounded-full shadow-xl lg:w-20 lg:h-20"
              :src="showCustomAvatar"
              @click="changeCustomAvatar()"
              :class="{
                'border-secondary': userInfo.avatar == showCustomAvatar,
                'border-transparent': userInfo.avatar != showCustomAvatar,
                'border-dashed': avatarLoading == showCustomAvatar,
              }"
            />
            <!-- 'border-transparent': userInfo.avatar != showCustomAvatar, -->
          </div>
        </div>
        <div class="lg:flex-1">
          <div id="user-data" v-if="!isSkin">
            <div class="input-container">
              <label for="name" class="label">Nome:</label>
              <input
                type="text"
                id="name"
                v-model="userInfo.name"
                @change="updateStoreUser('name', userInfo.name)"
                class="rounded-full bg-gray-700 px-5 py-1 w-full"
              />
            </div>
            <div class="input-container">
              <label for="email" class="label">Email:</label>
              <input
                type="text"
                id="email"
                class="rounded-full bg-gray-700 px-5 py-1 w-full"
                v-model="userInfo.email"
                @change="updateStoreUser('email', userInfo.email)"
              />
            </div>
          </div>
          <div id="settings-default">
            <div>
              <h2 class="title" id="radio-input-title">Mostra il credito:</h2>
              <div class="flex space-x-7">
                <label class="radio radio-before">
                  <span class="radio__label">Sì</span>
                  <span class="radio__input">
                    <input
                      type="radio"
                      :checked="settings.displayCredits"
                      @change="updateStoreSettings('displayCredits', true)"
                    />
                    <span class="radio__control"></span>
                  </span>
                </label>
                <label class="radio radio-before">
                  <span class="radio__label">No</span>
                  <span class="radio__input">
                    <input
                      type="radio"
                      :checked="!settings.displayCredits"
                      @change="updateStoreSettings('displayCredits', false)"
                    />
                    <span class="radio__control"></span>
                  </span>
                </label>
              </div>
            </div>
            <div>
              <h2 class="title" id="radio-input-title">
                Dopo il login, preferisci l'accesso a:
              </h2>
              <div class="flex space-x-7">
                <label class="radio radio-before">
                  <span class="radio__label">Mappa</span>
                  <span class="radio__input">
                    <input
                      type="radio"
                      :checked="!settings.goToBar"
                      @change="updateStoreSettings('goToBar', false)"
                    />
                    <span class="radio__control"></span>
                  </span>
                </label>

                <label class="radio radio-before">
                  <span class="radio__label">Bar</span>
                  <span class="radio__input">
                    <input
                      type="radio"
                      :checked="settings.goToBar"
                      @change="updateStoreSettings('goToBar', true)"
                    />
                    <span class="radio__control"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="user-figure">
        <h2 class="title mb-0.5">Scegli la tua figura di gioco:</h2>
        <div class="figure-container">
          <div
            class="flex justify-center flex-wrap gap-x-1 gap-y-1 w-5/12 border-r overflow-y-auto"
          >
            <button
              v-for="figure in figuresF"
              :key="figure.name"
              @click="updateFigure(figure)"
              class="justify-center"
            >
              <img
                class="avatar-img bg-gray-700 border-transparent"
                :src="'../../' + figure.src"
              />
              <span class="text-xs">{{ figure.slug }}</span>
            </button>

            <button
              v-for="figure in figuresM"
              :key="figure.name"
              @click="updateFigure(figure)"
              class="justify-center"
            >
              <img
                class="avatar-img bg-gray-700 border-transparent"
                :src="'../../' + figure.src"
              />
              <span class="text-xs">{{ figure.slug }}</span>
            </button>
          </div>
          <div class="swiper flex-1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="body in selectedFigure.body"
                :key="body.id"
              >
                <div class="h-full">
                  <img
                    class="h-full mx-auto touchable"
                    :src="'../../' + body.src"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatars from "@/helpers/avatars";
import minWidthMixin560 from "@/mixins/minWidthMixin560";
import settingsRepository from "@/api/settingsRepository";
import figures from "@/helpers/figures";
import Swiper from "swiper";

export default {
  name: "Settings",
  mixins: [minWidthMixin560],

  data() {
    return {
      avatarName: null,
      avatarLoading: null,
      userId: this.$store.getters["userInfo/getUserId"],
      timeout: null,
      prevRoute: null,
      imgForm: new FormData(),
      avatar: null,
      figures: figures,
      figuresM: figures.filter((figure) => figure.gender == "m"),
      figuresF: figures.filter((figure) => figure.gender == "f"),
      selectedFigure: figures[0],
      swiper: null,
      // settings: this.$store.getters["userInfo/getSettings"],
      // user: this.$store.getters["userInfo/getUser"],
      avatars: avatars,
      settings: null,
      userInfo: {
        nickname: null,
        email: null,
        avatar: null,
        createdAt: null,
        updatedAt: null,
        externalId: null,
        id: null,
        name: null,
        numContract: null,
        password: null,
        userSettingId: null,
      },
      isSkin: process.env.VUE_APP_IS_SKIN_INTEGRATED,
      loadingCustomAvatar: false,

      // showAvatarCustom: this.$store.getters["userInfo/getCustomAvatar"],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },

  watch: {
    settings: {
      handler(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store
            .dispatch("userInfo/updateSettings")
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }, 2000);
      },
      deep: true,
    },

    userInfo: {
      handler(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store
            .dispatch("userInfo/updateUserInfo")
            .then(() => {
              this.avatarLoading = null;
            })
            .catch((err) => {
              console.log(err);
            });
        }, 2000);
      },
      deep: true,
    },
  },

  created() {
    this.startEventMW560();
  },

  beforeMount() {
    //     //this.showAvatar = this.$store.getters["userInfo/getAvatar"];
    //     //this.showAvatarCustom = this.$store.getters["userInfo/getCustomAvatar"];
    //     //this.userInfo = this.$store.getters["userInfo/getUser"];
    this.settings = this.$store.state.userInfo.settings;
    this.userInfo = this.$store.state.userInfo.user;
  },

  mounted() {
    this.swiper = new Swiper(".swiper", {
      centeredSlides: true,
      spaceBetween: 100,
      observer: true,
      observeParents: true,

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    let ind;
    for (let figure of this.figures) {
      let actualFigure = figure.body.find(
        (obj) => obj.id == this.settings.figureId
      );
      if (actualFigure) {
        this.selectedFigure = figure;
        ind = this.selectedFigure.body.indexOf(actualFigure);
        break;
      }
    }
    this.swiper.slideTo(ind, 0, false);

    this.swiper.on("activeIndexChange", () => {
      if (this.selectedFigure) {
        this.settings.figureId =
          this.selectedFigure.body[this.swiper.activeIndex].id;
      }
    });
  },

  computed: {
    showAvatar() {
      return this.loadingCustomAvatar
        ? "https://i.gifer.com/PVtR.gif"
        : this.$store.getters["userInfo/getAvatar"];
    },

    showCustomAvatar() {
      return this.loadingCustomAvatar
        ? "https://i.gifer.com/PVtR.gif"
        : this.$store.getters["userInfo/getCustomAvatar"];
    },
  },

  methods: {
    replaceByDefault(e) {
      e.target.src = "/img/default_img.jpg";
    },

    updateFigure(figure) {
      this.selectedFigure = figure;
      this.settings.figureId =
        this.selectedFigure.body[this.swiper.activeIndex].id;
      this.swiper.slideTo(0, 300, false);
    },

    changeAvatar(avatarName) {
      console.log(1);
      this.avatarLoading = avatarName;
      this.updateStoreUser("avatar", avatarName);
    },

    changeCustomAvatar(avataName) {
      //this.changeAvatar(null);
      this.loadingCustomAvatar = true;
      console.log(2);
      this.getCustomAvatar(true);
    },

    updateStoreSettings(key, value) {
      console.log(3);
      if (key == "displayCredits") {
        this.$store.commit("updateShowCredit", value);
      }
      this.$store.commit("userInfo/mutateSettingsKey", { key, value });
    },

    updateStoreUser(key, value) {
      console.log(4);
      this.$store.commit("userInfo/mutateUserKey", { key, value });
    },

    goBack() {
      if (this.prevRoute === "Login") {
        this.$router.push({ name: "SlotsBar" });
      } else {
        this.$router.go(-1);
      }
    },

    /* ************************************************** */

    getLocalAvatarPath(avatar) {
      console.log(5);
      const path = this.$store.getters["userInfo/localAvatarPath"](avatar);
      return path;
    },

    uploadCustomAvatar(e) {
      console.log(6);
      this.loadingCustomAvatar = true;
      this.imgForm.set("file", e.target.files[0]);
      this.updateStoreUser("avatar", this.avatar);
      /// dispatch first
      this.$store
        .dispatch("userInfo/updateAvatar", this.imgForm)
        .then(() => {
          this.getCustomAvatar(true);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCustomAvatar(updateStore = false) {
      console.log(7);
      this.$store
        .dispatch("userInfo/getAvatar")
        .then((response) => {
          if (updateStore) {
            //QUIE ITO?
            this.avatarName = response.photo;
            this.updateStoreUser("avatar", response.photo);
          }

          this.customAvatar = response.photo;
          this.$store.commit("userInfo/mutateAvatarCustom", response.photo);
          setTimeout(() => {
            this.loadingCustomAvatar = false;
          }, 800);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getImgData(e) {
      console.log(8);
      this.loadingCustomAvatar = true;
      this.imgForm.set("file", e.target.files[0]);
      this.changeCustomAvatar();
    },

    uploadAvatarImg() {
      this.$refs.imgUpload.click();
    },
  },
};
</script>

<style lang="postcss" scoped>
.avatar-img {
  @apply w-12 h-12 object-cover border-2 rounded-full shadow-xl min-w-0;

  @screen md {
    @apply w-16 h-16;
  }

  @screen lg {
    @apply w-20 h-20;
  }
}

.settings {
  @apply h-full pb-0.5;
}

.user-settings {
  @apply w-full h-full overflow-y-auto space-y-4 mx-auto;

  @screen lg {
    padding-left: calc((100% - 1020px) / 2);
    padding-right: calc((100% - 1020px) / 2);
    @apply flex justify-center;
  }

  & > * {
    @apply px-6;
  }

  #user-avatar {
    @apply flex flex-col justify-center space-y-2 md:space-y-3 pt-4;

    @screen lg {
      @apply min-w-fit;
    }

    #avatar-cover {
      @apply w-20 h-20 p-0.5 md:p-1 object-cover rounded-full;
    }
  }

  #user-data {
    margin-top: 1.5rem;
    @apply space-y-3;
  }

  #settings-default {
    @apply space-y-4;

    #radio-input-title {
      margin-top: 0.75rem;
      margin-bottom: 0.25rem;
    }

    .radio {
      display: flex;
      font-size: 1rem;
      color: var(--color);
    }

    .radio__label {
      line-height: 1;
      transition: 180ms all ease-in-out;
      opacity: 1;
      margin-right: 0.65em;
      @apply text-sm;
    }

    .radio__input {
      display: flex;
      @apply items-center;

      input {
        display: none;
      }
    }

    .radio-gradient input:checked + .radio__control {
      background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
    }

    .radio-before {
      .radio__control {
        @apply flex items-center justify-center;
      }

      input + .radio__control::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        box-shadow: inset 0.5em 0.5em currentColor;
        border-radius: 50%;
        @apply transition transform scale-0;
      }

      input:checked + .radio__control::before {
        transform: scale(1);
      }
    }

    .radio__control {
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      border: 0.1em solid currentColor;
      transform: translateY(-0.05em);
    }
  }

  #user-figure {
    @screen lg {
      @apply w-3/5 flex flex-col justify-center;
    }

    .figure-container {
      height: 40vh;
      @apply flex border-2 border-secondary bg-gray-700 rounded-xl;

      @screen lg {
        height: 70vh;
      }

      .swiper {
        z-index: 0;
        @apply overflow-hidden relative h-auto;
      }

      .swiper-slide {
        @apply px-6 py-1.5;
      }

      .swiper-button-next,
      .swiper-button-prev {
        @apply text-primary;

        &:after {
          @apply text-xl font-semibold;
        }
      }

      .swiper-button-prev {
        @apply left-0;
      }

      .swiper-button-next {
        @apply right-0;
      }
    }
  }
}
</style>
